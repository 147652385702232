<template>
  <LayoutBase>
    <BasicAppHeader />

    <v-layout class="overflow-inherit!">
      <v-main :class="{ 'pt-30! pb-12!': !noPad }">
        <slot />
      </v-main>
    </v-layout>

    <DefaultAppFooter />

    <div class="md:hidden">
      <MobileNavFooter />
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/LayoutBase.vue";
import { BasicAppHeader, DefaultAppFooter } from "~/components/layout";
import MobileNavFooter from "@/components/layout/footers/MobileNavFooter.vue";

export default defineComponent({
  components: {
    LayoutBase,
    BasicAppHeader,
    DefaultAppFooter,
    MobileNavFooter,
  },
  setup() {
    const route = useRoute();
    const noPad = computed(() => route.meta.noAppHeaderPadding);

    return {
      noPad,
    };
  },
});
</script>
